const btn_reserve = document.querySelector(
    'button.reserve'
) as HTMLButtonElement

btn_reserve.addEventListener('click', function () {
    const rem = 1

    requestAnimationFrame(() => {
        const px =
            parseFloat(getComputedStyle(document.documentElement).fontSize) *
            rem
        const rect = this.getBoundingClientRect()
        const scrollAmount = rect.top + window.scrollY - px

        window.scrollTo({
            top: scrollAmount,
            behavior: 'smooth',
        })
    })
})

const msg_bg_img = document.querySelector(
    'section.message img'
) as HTMLImageElement
msg_bg_img.style.filter = `opacity(${msg_bg_img.getAttribute('data-opacity')}%)`

// Get the 'booksy' button element from the document
// const btn_booksy = document.getElementById('booksy') as HTMLButtonElement

// Add a click event listener to the 'booksy' button
// btn_booksy.addEventListener('click', _ => {
//     const sel_members = document.getElementById('members') as HTMLSelectElement

//     if (sel_members.value.trim() !== '') {
//         const newWindow = window.open(sel_members.value, '_self', 'noopener')
//         if (newWindow) newWindow.opener = null
//     }
// })

const highlights = document.querySelector(
    'section.highlights div.mobile'
) as Element
const highlight_images = highlights.querySelectorAll('ul.images li')
const highlight_buttons = highlights.querySelectorAll('ul.nav li')
const caption = highlights.querySelector('p span.caption') as HTMLElement

let current_index = 0
let intervalId: number

const cycleHighlightImages = (index?: number) => {
    // If an index is provided, use it. Otherwise, use the current index.
    current_index = index !== undefined ? index : current_index

    // Hide all images
    highlight_images.forEach(item => {
        item.classList.remove('is-visible')
        // Remove 'selected' class from corresponding nav element
        const nav_el = document.querySelector(`[data-target="${item.id}"]`)
        if (nav_el) nav_el.classList.remove('selected')
    })

    // Show the current image
    const current_image = highlight_images[current_index]
    current_image?.classList.add('is-visible')

    // Add 'selected' class to corresponding nav element
    const nav_el = document.querySelector(
        `[data-target="${current_image?.id}"]`
    )
    if (nav_el) nav_el.classList.add('selected')

    // Update the caption
    const title = nav_el?.getAttribute('data-title') || ''
    const by = nav_el?.getAttribute('data-by') || ''

    if (title.length > 0 && by.length > 0) {
        caption.innerHTML = `<u>${title}</u> by ${by}`
    } else if (title.length > 0) {
        caption.innerHTML = `<u>${title}</u>`
    }

    // Increment the current index after displaying the image
    current_index = (current_index + 1) % highlight_images.length
}

// Start the cycle
intervalId = setInterval(cycleHighlightImages, 7000)

// Add click event listener to each button
highlight_buttons.forEach((button, index) => {
    button.addEventListener('click', _ => {
        // Clear the interval
        clearInterval(intervalId)

        // Call cycleHighlightImages with the index of the clicked button
        cycleHighlightImages(index)

        // Restart the interval
        intervalId = setInterval(cycleHighlightImages, 5000)
    })
})

// Set Message Background Opacity

// const cycleHighlightImages = () => {
//     // Hide all images
//     highlight_images.forEach(item => {
//         item.classList.remove('is-visible')
//         // Remove 'selected' class from corresponding nav element
//         const nav_el = document.querySelector(`[data-target="${item.id}"]`)
//         if (nav_el) nav_el.classList.remove('selected')
//     })

//     // Show the current image
//     const current_image = highlight_images[current_index]
//     current_image?.classList.add('is-visible')

//     // Add 'selected' class to corresponding nav element
//     const nav_el = document.querySelector(
//         `[data-target="${current_image?.id}"]`
//     )
//     if (nav_el) nav_el.classList.add('selected')

//     // Update the index
//     current_index = (current_index + 1) % highlight_images.length
// }

// // Start the cycle
// setInterval(cycleHighlightImages, 5000)

// const root = document.querySelector('div.wrapper')

// Section Actions
// const sections = document.querySelectorAll('section')
// const sectionObserver = new IntersectionObserver(
//     entries => {
//         entries.forEach(entry => {
//             entry.target.classList.toggle('is-visible', entry.isIntersecting)
//             if (entry.isIntersecting) sectionObserver.unobserve(entry.target)
//         })
//     },
//     {
//         root,
//         rootMargin: '0px 0px 20px 0px',
//     }
// )
// sections.forEach(section => {
//     sectionObserver.observe(section)
// })

// const breaks = document.querySelectorAll('section.blog li.post h2')
// breaks.forEach(breakElement => {
//     const text = (breakElement as HTMLElement).innerText
//     const newText = text.replace(/:/g, ':\n')
//     ;(breakElement as HTMLElement).innerHTML = newText.replace(/\n/g, '<br/>')
// })

// // Video Actions
// const heroVideo = document.getElementById('heroVideo') as HTMLVideoElement

// const loadVideo = () => {
//     heroVideo.innerHTML =
//         '<source src="/assets/_video/placeholder.mp4" type="video/mp4">'
//     // if (window.innerWidth < 768) {
//     //     heroVideo.innerHTML =
//     //         '<source src="/assets/_video/nwd-placeholder-video-360.mp4" type="video/mp4">'
//     // } else {
//     //     heroVideo.innerHTML =
//     //         '<source src="/assets/_video/nwd-placeholder-video-720.mp4" type="video/mp4">'
//     // }
//     heroVideo.load()

//     heroVideo.removeEventListener('canplay', loadVideo)
// }
// loadVideo()

// const videoObserver = new IntersectionObserver(
//     entries => {
//         entries.forEach(entry => {
//             if (!entry.isIntersecting) {
//                 heroVideo.pause()
//             } else {
//                 heroVideo.play()
//             }
//         })
//     },
//     {
//         root,
//         threshold: 0.75,
//     }
// )
// videoObserver.observe(heroVideo)

// const muteSound = () => {
//     if (heroVideo.muted) {
//         heroVideo.muted = false
//     } else {
//         heroVideo.muted = true
//     }
// }
// const btnHideVideo = document.getElementById('hideVideo') as HTMLButtonElement

// const shutdownVideo = () => {
//     const div = btnHideVideo.parentElement as HTMLDivElement
//     heroVideo.muted = true
//     div.classList.add('hide')

//     setTimeout(() => {
//         heroVideo.removeEventListener('click', muteSound)
//         heroVideo.removeEventListener('ended', shutdownVideo)
//         btnHideVideo.removeEventListener('click', shutdownVideo)

//         videoObserver.unobserve(heroVideo)

//         div.remove()
//     }, 1000)
// }

// // heroVideo.addEventListener('canplay', loadVideo)
// heroVideo.addEventListener('click', muteSound)
// heroVideo.addEventListener('ended', shutdownVideo)
// btnHideVideo.addEventListener('click', shutdownVideo)
